@import url("https://use.typekit.net/ulg4hxz.css");


$backgroundColor: #ffffff;
$bodyColor: #3f3f3f;
$bodyFont: 'hightower-text', serif;

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 400;
  
  #box {
    margin: 0 auto 0;
    padding: 0;
    text-align: center;
    position: fixed;
    top: 42%;
    width: 100%;
    
    h1 {
      font-size: 4em;
      font-weight: 400;
      padding: 0;
      margin: 0 auto;
      letter-spacing: -.02em;
    }
    h2 {
      font-size: 1.5em;
      font-weight: 400;
      font-style: italic;
      padding: 0;
      margin: 0 auto;
    }
  }
  
  #foot {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    padding: 0;
    margin: 0;
    
    p {
      font-size: .8em;
      font-weight: 400;
      color: #ddd;
      margin: 0 auto;
      a {
        text-decoration: none;
        color: inherit;
        
        &:hover {
          color: #f00;
        }
      }
    }
  }
}
